<template>
  <div class=container>
        <table class=table width="982" border="0" cellspacing="30" cellpadding="0">
            <tbody>
                <tr>
                    <td>   
                        <div align="center">Gallery of HDR Image results</div>
                    </td>
                </tr>
            <tr>
                <td>
                    <p>We show several examples of HDR reconstruction results and the comparisons. By placing the mouse pointer on different thumbnail images, you can compare different HDR reconstruction methods.
                    </p>
                    <p><strong>The result achieved with our method is highlighted by having red caption.</strong>
                    </p>
                    <p>Also, by clicking on the thumbnail images, you can see the corresponding high resolution image.
                    </p>
                </td>
            </tr>
        <tr>
            <td>
            <table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody>
                    <tr>
                        <td>
                        <table width="100%" border="0" cellspacing="10" cellpadding="0">
                                <tbody>
                                    <tr>
                                        <td>
                                        <strong>Room with Candle Lights (Camera Motion)</strong>
                                        </td>
                  </tr>
                </tbody></table>
                <div align="right"></div></td>
                </tr>
                <tr>
                <td>
		<table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
            <td>
			<div align="center">
            <canvas id="candle_room_main" width="500" height="428" style="border: solid 2px #000000;"></canvas>
			</div>
		</td>
		<td>
			<div align="center">
			<canvas id="candle_room_crop1" width="159" height="180" style="border: 2px solid rgb(220, 220, 220);"></canvas>
			</div>
		</td>
		</tr>
                </tbody></table>
                </td>
              </tr><tr>
                <td>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                  <tbody><tr>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/ldr.jpg" width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/ldr.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                          </div></td>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/exponential.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/exponential.jpg" width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/exponential.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption>HDR: Exponential<br>[Debevec and Malik 1997]</figcaption></a></figure>
                          </div></td>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/alternating.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/alternating.jpg"  width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/alternating.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption>HDR: Alternating Exposure<br>[Kang et al. 2003]</figcaption></a></figure>
                          </div></td>
		</tr>
		<tr>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/burst.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/burst.jpg" width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/burst.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption>HDR: Burst Exposure<br>[Zhang et al. 2010]</figcaption></a></figure>
                          </div></td>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/fibonacci_nr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/fibonacci_nr.jpg" width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/fibonacci_nr.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption>HDR: Fibonacci No Registration</figcaption></a></figure>
                          </div></td>
                    <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/thumbnails/fibonacci.jpg" width="170" border="0" @mouseover="ChangeImage('candle_room', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/fibonacci.jpg', '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration<br>[This paper]</span></figcaption></a></figure>
                          </div></td>
                   </tr>
                </tbody></table>                
		</td>
              </tr>
          </tbody></table></td>
          </tr>

          <!-- Times Square -->
        <tr>
        <td>
            <table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td>
		<table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
		<strong>Lights in Night (Camera and Scene Motion)</strong>
    </td>
    </tr>
    </tbody></table>
    <div align="right"></div></td>
                </tr>
                <tr>
                <td>
		<table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
		<td>
		<div align="center">
		<canvas id="times_square_main" width="500" height="357" style="border: solid 2px #000000;"></canvas>
        </div>
		</td>
		<td>
		<div align="center">
		<canvas id="times_square_crop1" width="160" height="139" style="border: 2px solid rgb(220, 220, 220);"></canvas>
		</div>
		</td>
		</tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/thumbnails/ldr.jpg" width="297" border="0" @mouseover="ChangeImage('times_square', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/ldr.jpg', '#CCFFFF', 1, [{'x':676, 'y':290, 'width':90, 'height':78}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/exponential.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/thumbnails/exponential.jpg" width="297" border="0" @mouseover="ChangeImage('times_square', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/exponential.jpg', '#CCFFFF', 1, [{'x':676, 'y':292, 'width':90, 'height':78}])"><figcaption>HDR: Exponential</figcaption></a></figure>
                        </div></td>
		</tr>
		<tr>
            <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/fibonacci_nr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/thumbnails/fibonacci_nr.jpg" width="297" border="0" @mouseover="ChangeImage('times_square', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/fibonacci_nr.jpg', '#CCFFFF', 1, [{'x':676, 'y':290, 'width':90, 'height':78}])"><figcaption>HDR: Fibonacci No Registration</figcaption></a></figure>
                    </div></td>
            <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/thumbnails/fibonacci.jpg" width="297" border="0" @mouseover="ChangeImage('times_square', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/fibonacci.jpg', '#CCFFFF', 1, [{'x':676, 'y':290, 'width':90, 'height':78}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration</span></figcaption></a></figure>
                    </div></td>
            </tr>
        </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Church Outside -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Overcast Day Outside Church (Camera Motion)</strong>
            </td>
                
        
                  </tr>
                </tbody></table>
                <div align="right"></div></td>
                </tr>
                <tr>
                <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td rowspan="2">
        <div align="center">
        <canvas id="church_outside_main" width="500" height="357" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="church_outside_crop1" width="160" height="160" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
        <tr>
        <td>
        <div align="center">
        <canvas id="church_outside_crop2" width="160" height="160" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/thumbnails/ldr.jpg" width="297" border="0" @mouseover="ChangeImage('church_outside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/ldr.jpg', '#CCFFFF', 2, [{'x':41, 'y':99, 'width':207, 'height':207}, {'x':652, 'y':10, 'width':207, 'height':207}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/alternating.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/thumbnails/alternating.jpg" width="297" border="0" @mouseover="ChangeImage('church_outside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/alternating.jpg', '#CCFFFF', 2, [{'x':41, 'y':99, 'width':207, 'height':207}, {'x':652, 'y':10, 'width':207, 'height':207}])"><figcaption>HDR: Alternating Exposure</figcaption></a></figure>
                        </div></td>
        </tr>
        <tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/burst.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/thumbnails/burst.jpg" width="297" border="0" @mouseover="ChangeImage('church_outside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/burst.jpg', '#CCFFFF', 2, [{'x':41, 'y':99, 'width':207, 'height':207}, {'x':652, 'y':10, 'width':207, 'height':207}])"><figcaption>HDR: Burst Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/thumbnails/fibonacci.jpg" width="297" border="0" @mouseover="ChangeImage('church_outside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/fibonacci.jpg', '#CCFFFF', 2, [{'x':41, 'y':99, 'width':207, 'height':207}, {'x':652, 'y':10, 'width':207, 'height':207}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>

        <!-- Birthday -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Happy Birthday (Scene Motion)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td rowspan="2">
        <div align="center">
        <canvas id="birthday_main" width="500" height="299" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="birthday_crop1" width="160" height="128" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
        <tr>
        <td>
        <div align="center">
        <canvas id="birthday_crop2" width="160" height="128" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/thumbnails/ldr.jpg" width="297" border="0" :@mouseover="ChangeImage('birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/ldr.jpg', '#CCFFFF', 2, [{'x':442, 'y':355, 'width':200, 'height':160}, {'x':768, 'y':490, 'width':200, 'height':160}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/alternating.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/thumbnails/alternating.jpg" width="297" border="0" @mouseover="ChangeImage('birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/alternating.jpg', '#CCFFFF', 2, [{'x':442, 'y':355, 'width':200, 'height':160}, {'x':768, 'y':490, 'width':200, 'height':160}])"><figcaption>HDR: Alternating Exposure</figcaption></a></figure>
                        </div></td>
        </tr>
        <tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/burst.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/thumbnails/burst.jpg" width="297" border="0" @mouseover="ChangeImage('birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/burst.jpg', '#CCFFFF', 2, [{'x':442, 'y':355, 'width':200, 'height':160}, {'x':768, 'y':490, 'width':200, 'height':160}])"><figcaption>HDR: Burst Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/thumbnails/fibonacci.jpg" width="297" border="0" @mouseover="ChangeImage('birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/fibonacci.jpg', '#CCFFFF', 2, [{'x':442, 'y':355, 'width':200, 'height':160}, {'x':768, 'y':490, 'width':200, 'height':160}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Riverside Park -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Running in the Park (Scene Motion)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td rowspan="2">
        <div align="center">
        <canvas id="riverside_park_main" width="500" height="281" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="riverside_park_crop1" width="160" height="147" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
        <tr>
        <td>
        <div align="center">
        <canvas id="riverside_park_crop2" width="160" height="147" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/thumbnails/ldr.jpg" width="297" border="0" @mouseover="ChangeImage('riverside_park', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/ldr.jpg', '#CCFFFF', 2, [{'x':989, 'y':386, 'width':204, 'height':188}, {'x':770, 'y':33, 'width':204, 'height':188}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/alternating.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/thumbnails/alternating.jpg" width="297" border="0" @mouseover="ChangeImage('riverside_park', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/alternating.jpg', '#CCFFFF', 2, [{'x':989, 'y':386, 'width':204, 'height':188}, {'x':770, 'y':33, 'width':204, 'height':188}])"><figcaption>HDR: Alternating Exposure</figcaption></a></figure>
                        </div></td>
        </tr>
        <tr>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/burst.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/thumbnails/burst.jpg" width="297" border="0" @mouseover="ChangeImage('riverside_park', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/burst.jpg', '#CCFFFF', 2, [{'x':972, 'y':386, 'width':204, 'height':188}, {'x':770, 'y':33, 'width':204, 'height':188}])"><figcaption>HDR: Burst Exposure</figcaption></a></figure>
                        </div></td>
                <td width="32%" valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/thumbnails/fibonacci.jpg" width="297" border="0" @mouseover="ChangeImage('riverside_park', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/fibonacci.jpg', '#CCFFFF', 2, [{'x':989, 'y':386, 'width':204, 'height':188}, {'x':770, 'y':33, 'width':204, 'height':188}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Soccer in the Park -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Soccer in the Park (Scene Motion)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td rowspan="2">
        <div align="center">
        <canvas id="riverside_park_soccer_main" width="500" height="312" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="riverside_park_soccer_crop1" width="160" height="160" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
        <tr>
        <td>
        <div align="center">
        <canvas id="riverside_park_soccer_crop2" width="160" height="160" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/thumbnails/ldr.jpg" width="180" border="0" @mouseover="ChangeImage('riverside_park_soccer', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/ldr.jpg', '#CCFFFF', 2, [{'x':542, 'y':592, 'width':110, 'height':110}, {'x':694, 'y':560, 'width':110, 'height':110}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/conventional.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/thumbnails/conventional.jpg" width="180" border="0" @mouseover="ChangeImage('riverside_park_soccer', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/conventional.jpg', '#CCFFFF', 2, [{'x':542, 'y':592, 'width':110, 'height':110}, {'x':694, 'y':560, 'width':110, 'height':110}])"><figcaption>HDR: Fibonacci Sequence + Conventional Registration</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/generalized.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/thumbnails/generalized.jpg" width="180" border="0" @mouseover="ChangeImage('riverside_park_soccer', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/generalized.jpg', '#CCFFFF', 2, [{'x':542, 'y':592, 'width':110, 'height':110}, {'x':694, 'y':560, 'width':110, 'height':110}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Sequence + Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Chocolate Shop -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Chocolate Shop (Camera Motion)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td>
        <div align="center">
        <canvas id="chocolate_shop_main" width="500" height="342" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="chocolate_shop_crop1" width="180" height="72" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/thumbnails/ldr.jpg" width="180" border="0" @mouseover="ChangeImage('chocolate_shop', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/ldr.jpg', '#CCFFFF', 1, [{'x':133, 'y':609, 'width':290, 'height':116}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/conventional.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/thumbnails/conventional.jpg" width="180" border="0" @mouseover="ChangeImage('chocolate_shop', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/conventional.jpg', '#CCFFFF', 1, [{'x':133, 'y':609, 'width':290, 'height':116}])"><figcaption>HDR: Fibonacci Sequence + Conventional Registration</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/generalized.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/thumbnails/generalized.jpg" width="180" border="0" @mouseover="ChangeImage('chocolate_shop', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/generalized.jpg', '#CCFFFF', 1, [{'x':133, 'y':609, 'width':290, 'height':116}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Sequence + Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Cole Haan -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Window Shopping (Camera Motion)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td>
        <div align="center">
        <canvas id="cole_haan_main" width="500" height="372" style="border: solid 2px #000000;"></canvas>

        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="cole_haan_crop1" width="160" height="106" style="border: 2px solid rgb(220, 220, 220);"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/thumbnails/ldr.jpg" width="180" border="0" @mouseover="ChangeImage('cole_haan', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/ldr.jpg', '#CCFFFF', 1, [{'x':200, 'y':253, 'width':242, 'height':160}])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/conventional.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/thumbnails/conventional.jpg" width="180" border="0" @mouseover="ChangeImage('cole_haan', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/conventional.jpg', '#CCFFFF', 1, [{'x':200, 'y':253, 'width':242, 'height':160}])"><figcaption>HDR: Fibonacci Sequence + Conventional Registration</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/generalized.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/thumbnails/generalized.jpg" width="180" border="0" @mouseover="ChangeImage('cole_haan', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/generalized.jpg', '#CCFFFF', 1, [{'x':200, 'y':253, 'width':242, 'height':160}])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Sequence + Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>

        <!-- Campus -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Comparison with Non-linear Camera Response (gamma=2.2)</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td>
        <div align="center">
        <canvas id="campus_main" width="700" height="334" style="border: solid 2px #000000;"></canvas>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/ldr.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/thumbnails/ldr.jpg" width="180" border="0" @mouseover="ChangeImage('campus', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/ldr.jpg', '#CCFFFF', 0, [])"><figcaption>LDR: Single Best Exposure</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/exponential.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/thumbnails/exponential.jpg" width="180" border="0" @mouseover="ChangeImage('campus', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/exponential.jpg', '#CCFFFF', 0, [])"><figcaption>HDR: Exponential</figcaption></a></figure>
                        </div></td>
                <td valign="top"><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/fibonacci.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/thumbnails/fibonacci.jpg" width="180" border="0" @mouseover="ChangeImage('campus', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/fibonacci.jpg', '#CCFFFF', 0, [])"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Fibonacci Generalized Registration</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>


        <!-- Church inside -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Comparison at Different Sensor Bit Depths</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td>
        <div align="center">
        <canvas id="church_inside_8bit_main" width="400" height="242"></canvas>
        <br><strong>12 bits</strong>
        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="church_inside_crop1_1" width="180" height="101" style="border: 2px solid rgb(255, 255, 255);"></canvas>
        </div>
        </td>
        </tr>
                <tr>
        <td>
        <div align="center">
        <canvas id="church_inside_12bit_main" width="400" height="242"></canvas>
        <br><strong>8 bits</strong>
        </div>
        </td>
        <td>
        <div align="center">
        <canvas id="church_inside_crop1_2" width="180" height="101" style="border: 2px solid rgb(205, 205, 205);"></canvas>
        </div>
        </td>
        </tr>

            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td><div align="center" valign="top"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/burst_12bit.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/thumbnails/burst_12bit.jpg" width="180" border="0" @mouseover="ChangeDifferentBitdepthImage('church_inside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/burst', '#CCFFFF', 1, [{'x':560, 'y':238, 'width':234, 'height':147}])"><figcaption>Burst Exposure</figcaption></a></figure>
                        </div></td>
                <td><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/alternating_12bit.jpg" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/thumbnails/alternating_12bit.jpg" width="180" border="0" @mouseover="ChangeDifferentBitdepthImage('church_inside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/alternating', '#CCF3300', 1, [{'x':560, 'y':248, 'width':234, 'height':147}])"><figcaption>Alternating Exposure</figcaption></a></figure>
                        </div></td>
                <td><div align="center"><figure><a href="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/fibonacci_12bit.jpg" style="color:#800000;" target="new"><img src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/thumbnails/fibonacci_12bit.jpg" width="180" border="0" @mouseover="ChangeDifferentBitdepthImage('church_inside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/fibonacci', '#0099FF', 1, [{'x':560, 'y':238, 'width':234, 'height':147}])"><figcaption><span style="color:#800000; font-weight:BOLD;">Fibonacci Exposure</span></figcaption></a></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>

        <!-- Church video -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Scene-Adaptive Exposure Bracketing Video Results: Inside Church</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td align="top">
        <div align="center">
        <video id="adaptive_church" width="750" style="position:relative; right:0px; top:60px" controls>
            <source id="adaptive_church_src"  src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/short_ldr.mov" type="video/mp4">
            <source id="adaptive_church_src"  src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/short_ldr.ogg" type="video/ogg">
        </video>
        <video id="adaptive_church_plot" width="180" style="visibility:hidden; position:relative; right:-285px; top:-383px" controls="">
            <source id="adaptive_church_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/church_plot.mov" type="video/mp4">
            <source id="adaptive_church_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/church_plot.ogg" type="video/ogg">
        </video>               
        <div id="adaptive_church_plot_caption" align="center" style="position:relative; right:-284px; top:-533px; width:180px; background-color:#141414; color:#CC9900; font-weight:BOLD; visibility:hidden">Exposure Sequence</div>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center">
    <figure><img alt="LDR: Short Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/thumbnails/short_ldr.jpg" width="180" @click="ChangeVideo('adaptive_church', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/short_ldr', '#CCFFFF', '')"><figcaption>LDR: Short Exposure</figcaption></figure>
                        </div></td>
                <td valign="top"><div align="center">
    <figure><img alt="LDR: Best Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/thumbnails/best_ldr.jpg" width="180" @click="ChangeVideo('adaptive_church', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/best_ldr', '#CC3300', '')"><figcaption>LDR: Best Exposure</figcaption></figure>
    
                        </div></td>
                <td valign="top"><div align="center">
        <figure><img alt="HDR: Adaptive Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/thumbnails/adaptive_hdr.jpg" width="180" @click="ChangeVideo('adaptive_church', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/adaptive_hdr', '#0099FF', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/church/church_plot')"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Scene-Adaptive Exposure Bracketing</span></figcaption></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>

        <!-- Birthday video -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Scene-Adaptive Exposure Bracketing Video Results: Birthday Party</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td valign="top">
        <div align="center">
        <video id="adaptive_birthday" width="750" style="position:relative; right:0px; top:60px" controls="">
            <source id="adaptive_birthday_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/short_ldr.mov" type="video/mp4">
            <source id="adaptive_birthday_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/short_ldr.ogg" type="video/ogg">
        </video>
        <video id="adaptive_birthday_plot" width="180" style="visibility:hidden; position:relative; right:-285px; top:-408px" controls="">
            <source id="adaptive_birthday_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/birthday_plot.mov" type="video/mp4">
            <source id="adaptive_birthday_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/birthday_plot.ogg" type="video/ogg">
        </video>                
        <div id="adaptive_birthday_plot_caption" align="center" style="position:relative; right:-284px; top:-558px; width:180px; background-color:#141414; color:#CC9900; font-weight:BOLD; visibility:hidden">Exposure Sequence</div>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center">
    <figure><img alt="LDR: Short Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/thumbnails/short_ldr.jpg" width="180" @click="ChangeVideo('adaptive_birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/short_ldr', '#CCFFFF', '')"><figcaption>LDR: Short Exposure</figcaption></figure>
                        </div></td>
                <td valign="top"><div align="center">
    <figure><img alt="LDR: Best Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/thumbnails/best_ldr.jpg" width="180" @click="ChangeVideo('adaptive_birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/best_ldr', '#CC3300', '')"><figcaption>LDR: Best Exposure</figcaption></figure>
    
                        </div></td>
                <td valign="top"><div align="center">
        <figure><img alt="HDR: Adaptive Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/thumbnails/adaptive_hdr.jpg" width="180" @click="ChangeVideo('adaptive_birthday', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/adaptive_hdr', '#0099FF', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/birthday/birthday_plot')"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Scene-Adaptive Exposure Bracketing</span></figcaption></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>

        <!-- Waterfront video -->
        <tr>
        <td>
    <table width="100%" border="1" cellspacing="0" cellpadding="0">
            <tbody><tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
                <td>
            <strong>Scene-Adaptive Exposure Bracketing Video Results: Sunset at Waterfront</strong>
            </td>
                
        
                </tr>
            </tbody></table>
            <div align="right"></div></td>
            </tr>
            <tr>
            <td>
    <table width="100%" border="0" cellspacing="10" cellpadding="0">
                <tbody><tr>
        <td valign="top">
        <div align="center">
        <video id="adaptive_riverside" width="750" style="visibility:visible; position:relative; right:0px; top:60px" controls>
            <source id="adaptive_riverside_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/adaptive_hdr.mov" type="video/mp4">
            <source id="adaptive_riverside_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/adaptive_hdr.ogg" type="video/ogg">
        </video>
        <video id="adaptive_riverside_plot" width="180" style="visibility:visible; position:relative; right:-285px; top:-400px" controls="">
            <source id="adaptive_riverside_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/riverside_plot.mov" type="video/mp4">
            <source id="adaptive_riverside_plot_src" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/riverside_plot.ogg" type="video/ogg">
        </video>                
        <div id="adaptive_riverside_plot_caption" align="center" style="position:relative; right:-284px; top:-550px; width:180px; background-color:#141414; color:#CC9900; font-weight:BOLD; visibility:visible">Exposure Sequence</div>
        </div>
        </td>
        </tr>
            </tbody></table>
            </td>
            </tr><tr>
            <td>
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tbody><tr>
                <td valign="top"><div align="center">
        <figure><img alt="HDR: Adaptive Exposure" src="https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/thumbnails/adaptive_hdr.jpg" width="180" @click="ChangeVideo('adaptive_riverside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/adaptive_hdr', '#0099FF', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/videos/riverside/riverside_plot')"><figcaption><span style="color:#800000; font-weight:BOLD;">HDR: Scene-Adaptive Exposure Bracketing</span></figcaption></figure>
                        </div></td>
                </tr>
            </tbody></table>                
    </td>
            </tr>
        </tbody></table></td>
        </tr>
    
    </tbody></table>
</div>
</template>

<script>
export default {
    created(){
			// Load default image
			this.ChangeImage("candle_room", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/candle_room/ldr.jpg", '#CCFFFF', 1, [{'x':0, 'y':21, 'width':265, 'height':300}]);
			this.ChangeImage("times_square", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/times_square/ldr.jpg", '#CCFFFF', 1, [{'x':676, 'y':290, 'width':90, 'height':78}]);
            this.ChangeImage("church_outside", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_outside/ldr.jpg", '#CCFFFF', 2, [{'x':41, 'y':99, 'width':207, 'height':207}, {'x':652, 'y':10, 'width':207, 'height':207}]);
			this.ChangeImage("birthday", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/birthday/ldr.jpg", '#CCFFFF', 2, [{'x':442, 'y':355, 'width':200, 'height':160}, {'x':768, 'y':490, 'width':200, 'height':160}]);
			this.ChangeImage("riverside_park", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park/ldr.jpg", '#CCFFFF', 2, [{'x':989, 'y':386, 'width':204, 'height':188}, {'x':770, 'y':33, 'width':204, 'height':188}]);
			this.ChangeImage("riverside_park_soccer", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/riverside_park_soccer/ldr.jpg", '#CCFFFF', 2, [{'x':542, 'y':592, 'width':110, 'height':110}, {'x':694, 'y':560, 'width':110, 'height':110}]);
            this.ChangeImage("chocolate_shop", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/chocolate_shop/ldr.jpg", '#CCFFFF', 1, [{'x':133, 'y':609, 'width':290, 'height':116}]);
			this.ChangeImage("cole_haan", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/cole_haan/ldr.jpg", '#CCFFFF', 1, [{'x':200, 'y':253, 'width':242, 'height':160}]);
			this.ChangeImage("campus", "https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/campus/ldr.jpg", '#CCFFFF', 0, []);
            this.ChangeDifferentBitdepthImage('church_inside', 'https://cave.cs.columbia.edu/old/projects/fibonacci_hdr/ImageGallery/images/church_inside/burst', '#CCFFFF', 1, [{'x':560, 'y':238, 'width':234, 'height':147}])
			
    }
    ,
    methods:{
    ChangeImage(imageID, path, color, numInsets, rects) {
	var img = new Image();
	var colors = new Array();
	colors[0] = "rgb(220, 220, 220)";
	colors[1] = "rgb(220, 220, 220)";
	colors[2] = "rgb(172, 211, 115)";
	img.onload = function() {
    // Update the image	
    // Draw main image		
    var mainCanvas = document.getElementById(imageID + "_main");	
    var mainContext = mainCanvas.getContext('2d');
    // draw image
    mainContext.drawImage(img, 0, 0, mainCanvas.width, mainCanvas.height);
				
    var ratio = mainCanvas.width / img.width;
    // show partial image	
    for (var i = 1; i <= numInsets; i++) {
        // draw rectangles
        mainContext.strokeStyle = colors[i-1];
        mainContext.lineWidth = 3;
        
        mainContext.strokeRect(Math.round(ratio*rects[i-1].x), Math.round(ratio*rects[i-1].y), Math.round(ratio*rects[i-1].width), Math.round(ratio*rects[i-1].height));
        
        var canvas = document.getElementById(imageID + "_crop" + i );	
        canvas.style.borderColor = mainContext.strokeStyle;
        
        var context = canvas.getContext('2d');
        if (context) {
            context.drawImage(img, rects[i-1].x, rects[i-1].y, rects[i-1].width, rects[i-1].height, 0, 0, canvas.width, canvas.height);
            }
	}	
	}	
	img.src = path;	
},
ChangeDifferentBitdepthImage(imageID, path, color, numInsets, rects) {
	// Preload the image
	var NUM_BITS = 2;
	var bits = [12, 8];
	var imgs = new Array();
	for (var i = 0; i < NUM_BITS; i++) {
		imgs[i] = new Image();
		imgs[i].src = path + "_" +bits[i]+ "bit.jpg";
	}
	
	// Set event handler only for the last image
	imgs[NUM_BITS - 1].onload = function() {
		// Update the image
		// Draw main image		
		for (var bi = 0; bi < NUM_BITS; bi++) {
        var mainCanvas = document.getElementById(imageID + "_" +bits[bi]+ "bit_main");	
        var mainContext = mainCanvas.getContext('2d');
        // draw image
        mainContext.drawImage(imgs[NUM_BITS - bi - 1], 0, 0, mainCanvas.width, mainCanvas.height);
        var ratio = mainCanvas.width / imgs[bi].width;
        // show partial image	
        for (var i = 1; i <= numInsets; i++) {
            // draw rectangles
            var grayColor = 255 - 50 * bi;
            mainContext.strokeStyle = "rgb(" +grayColor+", " +grayColor+ ", " +grayColor+")";
            mainContext.lineWidth = 3;
            mainContext.strokeRect(Math.round(ratio*rects[i-1].x), Math.round(ratio*rects[i-1].y), Math.round(ratio*rects[i-1].width), Math.round(ratio*rects[i-1].height));
            var canvas = document.getElementById(imageID + "_crop" + i +"_" + (bi+1));	
            canvas.style.borderColor = mainContext.strokeStyle
            var context = canvas.getContext('2d');
            if (context) {
                context.drawImage(imgs[bi], rects[i-1].x, rects[i-1].y, rects[i-1].width, rects[i-1].height, 0, 0, canvas.width, canvas.height);
                }
			}
		}
	}	
},
ChangeVideo(videoID, path, color, plotPath) {
	var userAgent = window.navigator.userAgent.toLowerCase();
    var video = document.getElementById(videoID);
	var videoPlot = document.getElementById(videoID+"_plot");
	var plotCaption = document.getElementById(videoID+"_plot_caption");	
    // console.log('video',video)
    // console.log('plot',videoPlot)
    // console.log(plotCaption)
	if (userAgent.indexOf('msie') > -1) {
		// For Internet Explore
		document.getElementById(videoID+"_src").src = path + ".mov";
	
		if (plotPath != "") {
			document.getElementById(videoID+"_plot_src").src = plotPath + ".mov";
			videoPlot.style.visibility = "visible"
			plotCaption.style.visibility = "visible";
		}
		else {
			videoPlot.style.visibility = "hidden";
			plotCaption.style.visibility = "hidden";
		}

		video.load();
		video.play();
		if (plotPath != "") {
			videoPlot.load();
			videoPlot.play();
		}
		
	}
	else if (userAgent.indexOf('firefox') > -1) {
		// For Firefox
		video.src = path + ".ogg";
		if (plotPath != "") {
			videoPlot.src = plotPath + ".ogg";
			videoPlot.style.visibility = "visible"
			plotCaption.style.visibility = "visible";

		}
		else {
			videoPlot.style.visibility = "hidden";
			plotCaption.style.visibility = "hidden";
		}
		
		video.play();
		if (plotPath != "") {
			videoPlot.play();
		}
	}
	else {
		// For Safari and Chrome
		video.src = path + ".mov";
		if (plotPath != "") {

			videoPlot.src = plotPath + ".mov";
			videoPlot.style.visibility = "visible"
			plotCaption.style.visibility = "visible";
		}
		else {
			videoPlot.style.visibility = "hidden";
			plotCaption.style.visibility = "hidden";
		}
		
		video.play();
		if (plotPath != "") {
			videoPlot.play();
		}
	}
}

    }
}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
.table{
        margin:auto;
}
</style>